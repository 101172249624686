import {Button, Col, Row, Table} from "react-bootstrap";
import React, {useState} from "react";
import {Link, useLoaderData} from "react-router-dom";
import {Role} from "../../../models/role";
import {makeGetRolesCall} from "../../../services/authorization-service";
import {User} from "../../../models/user";
import { makeGetUsersCall } from "../../../services/user-service";

export const listUsersLoader = async(): Promise<User[]> => {
  return (await makeGetUsersCall()).response as User[];
};

export default function ListUsers() {
  const getUsersResponse = useLoaderData() as User[];
  const [usersResponse, setUsersResponse] = useState<User[]>(getUsersResponse);

  // Table
  const getTable = (): JSX.Element => {
    return (
      <Table responsive striped bordered hover>
        <thead>
        <tr>
          <th>Nombre de usuario</th>
          <th>Rol</th>
          <th>N° socio</th>
          <th>Nombre y apellido del socio</th>
          <th>Opciones</th>
        </tr>
        </thead>
        {getTableRows()}
      </Table>
    )
  }

  const getTableRows = () => {
    if (!usersResponse) {
      return <></>
    }

    const rolesRows = usersResponse.map((user: User) => (
      <tr key={user.id}>
        <td>{user.username}</td>
        <td>{user.role?.name}</td>
        <td>{user.member?.memberNumber}</td>
        <td>{`${user.member?.firstName ?? ''} ${user.member?.lastName ?? ''}`}</td>
        <td></td>
      </tr>
    ));

    return (
      <tbody>{rolesRows}</tbody>
    )
  }

  return (
    <Row>
      <h3>Listado</h3>
      <Col>
        {getTable()}
      </Col>
    </Row>
  )
}
