import React, {useEffect, useState} from 'react';
import './scss/custom.scss';
import {Outlet, useOutletContext} from "react-router-dom";
import {Col, Container, Row, ToastContainer} from "react-bootstrap";
import {useAuth} from "./hooks/use-auth";
import Sidebar from "./components/sidebar";
import TopNavbarNew from './components/top-navbar-new';
import ToastMessage from "./components/toast-message";
import {TOAST_FAILED_OPERATION_BODY, TOAST_FAILED_OPERATION_TITLE} from "./constants";
import {ToastConfiguration} from "./models/toast-configuration";

function App() {
  const { user } = useAuth();

  const [operationSuccessful, setOperationSuccessful] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastTitle, setToastTitle] = useState(TOAST_FAILED_OPERATION_TITLE);
  const [toastBody, setToastBody] = useState(TOAST_FAILED_OPERATION_BODY);

  const toastConfiguration: ToastConfiguration = {
    show: false,
    setShow: setShowToast,
    setOperationSuccessful: setOperationSuccessful,
    setTitle: setToastTitle,
    setBody: setToastBody
  };

  return (
    <div id="wrapper">
      { user ? <Sidebar /> : <></> }
      <div className="main-wrapper main-wrapper-responsive-lg w-100">
        <div id="content-wrapper" className="d-flex flex-column h-100">
          <div id="content">
            { user ? <TopNavbarNew /> : <></> }
            <div className="container-fluid">
              <Container className="pb-5">
                <Outlet context={{toastConfiguration}} />
                <ToastContainer containerPosition="fixed" className="p-3" position="bottom-end">
                  <ToastMessage onClose={() => setShowToast(false)} show={showToast} title={toastTitle} body={toastBody} variant={operationSuccessful ? 'success' : 'danger'}/>
                </ToastContainer>
              </Container>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

type ContextType = { toastConfiguration: ToastConfiguration | null };

export function useToastConfiguration() {
  return useOutletContext<ContextType>();
}

export default App;
