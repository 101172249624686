import {Button, Col, Row, Table} from "react-bootstrap";
import React, {useState} from "react";
import {Link, useLoaderData} from "react-router-dom";
import {Role} from "../../../models/role";
import {makeGetRolesCall} from "../../../services/authorization-service";

export const listRolesLoader = async(): Promise<Role[]> => {
  return (await makeGetRolesCall()).response as Role[];
};

export default function ListRoles() {
  const getRolesResponse = useLoaderData() as Role[];
  const [rolesResponse, setRolesResponse] = useState<Role[]>(getRolesResponse);

  // Table
  const getTable = (): JSX.Element => {
    return (
      <Table responsive striped bordered hover>
        <thead>
        <tr>
          <th>Rol</th>
          <th>Descripción</th>
          <th>Tareas</th>
          <th>Opciones</th>
        </tr>
        </thead>
        {getTableRows()}
      </Table>
    )
  }

  const getRoleTasks = (role: Role) => {
    const taskList = role.tasks.map((task) => <li>{task.name}</li>);
    return (
      <ul>
        {taskList}
      </ul>
    )
  }

  const getTableRows = () => {
    if (!rolesResponse) {
      return <></>
    }

    const rolesRows = rolesResponse.map((role: Role) => (
      <tr key={role.id}>
        <td>{role.name}</td>
        <td>{role.description}</td>
        <td>{getRoleTasks(role)}</td>
        <td>{}</td>
      </tr>
    ));

    return (
      <tbody>{rolesRows}</tbody>
    )
  }

  return (
    <Row>
      <h3>Listado</h3>
      <Col>
        {getTable()}
      </Col>
    </Row>
  )
}
