import { getDate } from "../../helpers";
import {router} from "../../router";
import {QR_CODE_ENDPOINT} from "../../constants";

interface Props {
  memberId: string;
  memberNumber: string;
  firstName: string;
  lastName: string;
  documentNumber: string;
  membershipDate: string;
  memberType: string;
  image?: string;
}

export default function Credential(props: Props) {
  return (
    <div className="outside credential">
      <div className="club-section">
        <div className="club-section-first-line">
          <p className="club-font">CLUB ATLÉTICO <strong>PACÍFICO</strong></p>
          <img className="club-logo" width="20%"
               src={`${router.basename}/pacifico.png`}/>
        </div>
      </div>
      <div className="main-section">
        <div className="picture-section">
          <img id="credential-member-picture" width="75%"
               src={props.image ?? `${router.basename}/default-profile-picture.jpeg`}/>
          <div className="data-field-value">
            <div className="data-field data-field-member-picture">
              <p className="label">N° DE SOCIO</p>
              <p className="value">{props.memberNumber.length > 0 ? props.memberNumber : "_____"}</p>
            </div>
          </div>
        </div>
        <div className="data-section">
          <div className="data-field-value">
            <div className="data-field">
              <p className="label">NOMBRE Y APELLIDO</p>
              <p className="value">{props.firstName.length > 0 || props.lastName.length > 0 ? `${props.firstName} ${props.lastName}` : "___________________"}</p>
            </div>
          </div>
          <div className="data-field-value">
            <div className="data-field">
              <p className="label">DOCUMENTO</p>
              <p className="value">{props.documentNumber.length > 0 ? props.documentNumber : "________"}</p>
            </div>
          </div>
          <div className="data-field-value">
            <div className="data-field">
              <p className="label">FECHA DE ALTA</p>
              <p className="value">{getDate(new Date(props.membershipDate), 'dd/mm/yyyy')}</p>
            </div>
          </div>
          <div className="data-field-value">
            <div className="data-field">
              <p className="label">CATEGORÍA</p>
              <p className="value">{props.memberType}</p>
            </div>
          </div>
        </div>
        <div className="qr-section">
          <span className="helper"></span>
          {
            props.memberId
              ? <img id="qr" width="90%" src={QR_CODE_ENDPOINT.replace('{MemberUrl}', `${window.location.origin}/members/status/${props.memberId}`)}/>
              : ""
          }
        </div>
      </div>
      <div className="club-section-bottom">
        <div className="club-section-bottom-line">
          <p className="club-font-slogan">El primero de la ciudad</p>
        </div>
      </div>
    </div>
  )
}
