// @ts-ignore
import {makeGetMemberByIdCall} from "../../services/member-service";
import {useToastConfiguration} from "../../App";
import {useState} from "react";
import {useLoaderData} from "react-router-dom";
import {Col, Row} from "react-bootstrap";
import {BUTTON_CONFIRM_UPDATE_LABEL} from "../../constants";
import {Operation} from "../../models/operation";
import ProductForm from "../../components/forms/product-form";
import {PeriodicProduct} from "../../models/periodic-product";
import {makeGetProductByIdCall} from "../../services/product-service";

// @ts-ignore
export const editMembershipFeeLoader = async({ params }): Promise<PeriodicProduct | undefined> => {
  return (await makeGetProductByIdCall(params.membershipFeeId))?.data;
};

export default function EditMembershipFee() {
  const { toastConfiguration } = useToastConfiguration();
  const [product, setProduct] = useState<PeriodicProduct>(useLoaderData() as PeriodicProduct);

  return (
    <Row>
      <Col md={{ offset: 2, span: 8 }}>
        <h2>Editar cuota social</h2>
        <p>Edite los datos de la cuota social "<p className="font-weight-bold">{product.name}</p>" y, cuando finalice, haga click en el botón "{BUTTON_CONFIRM_UPDATE_LABEL}".</p>
        <ProductForm product={product} discriminator="MembershipFee" operation={Operation.Edit} toastConfiguration={toastConfiguration}/>
      </Col>
    </Row>
  )
}
