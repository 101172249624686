import {Accordion, Button, Col, Form, Row, Table} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {makeGetMembershipFeesByMemberIdCall} from "../../services/product-service";
import {ProductPayment} from "../../models/membership-fee/product-payment";
import {getBooleanString, getDate, getProductRemainingAmount} from "../../helpers";
import {MembershipFee} from "../../models/membership-fee/membership-fee";
import { Member } from "../../models/member/member";
import PaymentPopup from "../payment-popup";
import {ToastConfiguration} from "../../models/toast-configuration";
import {DEFAULT_SHORT_DATE_TIME_FORMAT, MEMBERSHIP_FEE_DISCRIMINATOR } from "../../constants";
import {PartialPayment} from "../../models/partial-payment";
import {PeriodicProduct} from "../../models/periodic-product";
import CurrencyData from "../currency-data";

export interface Props {
  member: Member;
  toastConfiguration?: ToastConfiguration | null;
}

export default function MemberMembershipFees(props: Props) {
  const [membershipFeesYears, setMembershipFeesYears] = useState<string[]>([]);
  const [membershipFeesPayments, setMembershipFeesPayments] = useState(new Map());
  const [membershipFeesToPay, setMembershipFeesToPay] = useState<MembershipFee[]>([]);
  const [paymentsToMake, setPaymentsToMake] = useState<ProductPayment[]>([]);
  const [showPaymentConfirmationPopup, setShowPaymentConfirmationPopup] = useState(false);
  const [partialPriceEnabled, setPartialPriceEnabled] = useState<boolean>(false);
  const [partialPriceValue, setPartialPriceValue] = useState<number>(0);
  const [partialPayments, setPartialPayments] = useState<PartialPayment[]>([]);

  const getMembershipFeesByMemberId = async () => {
    const response = (await makeGetMembershipFeesByMemberIdCall(props.member.id!))?.data;
    if (!response) {
      return;
    }
    const keys = Object.keys(response.membershipFeesPayments);
    const entries = Object.entries(response.membershipFeesPayments);
    const map = new Map(entries);

    setMembershipFeesYears(keys.reverse());
    setMembershipFeesPayments(map);
  }

  useEffect(() => {
    getMembershipFeesByMemberId().then();
  }, []);

  const getTable = (year: string) => {
    return (
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Cuota</th>
            <th>Valor</th>
            <th>Fecha de pago</th>
            <th>Método de pago</th>
            <th>Importe abonado</th>
            <th>Aplicable</th>
            <th>Pagar</th>
          </tr>
        </thead>
        {getTableRows(year)}
      </Table>
    )
  }

  const handleCheckboxChange = (productPayment: ProductPayment) => {
    const index = membershipFeesToPay.findIndex((membershipFee) => membershipFee.id === productPayment.product.id);

    const payments: PeriodicProduct[] = []
    membershipFeesToPay.forEach(val => payments.push(Object.assign({}, val)));
    if (index < 0) {
      payments.push(productPayment.product);
      if (productPayment.remainingPriceToPay > 0 && productPayment.remainingPriceToPay !== productPayment.totalPriceToPay) {
        setPartialPayments([...partialPayments, { productId: productPayment.product.id!, partialPayment: productPayment.remainingPriceToPay }]);
      }
      setMembershipFeesToPay(payments);
      setPaymentsToMake([...paymentsToMake, productPayment]);
    }
    else {
      setMembershipFeesToPay(membershipFeesToPay.filter((membershipFee) => membershipFee.id !== productPayment.product.id));
      setPaymentsToMake(paymentsToMake.filter((pp) => pp.product.id !== productPayment.product.id));
    }
  }

  const getCheckbox = (membershipFeePayment: ProductPayment) => {
    if (membershipFeePayment.isApplicable && membershipFeePayment.remainingPriceToPay > 0) {
      return (
        <Form.Check key={membershipFeePayment.product.id} label="" disabled={partialPriceEnabled} checked={ membershipFeesToPay.findIndex(mf => mf.id === membershipFeePayment.product.id) >= 0 } onChange={() => handleCheckboxChange(membershipFeePayment)} />
      )
    }
    return <></>
  }

  const getTableRows = (year: string): JSX.Element => {
    if (!membershipFeesPayments) {
      return <></>
    }

    const membershipFeesRows = membershipFeesPayments.get(year).map((membershipFeePayment: ProductPayment) =>
      (
        <tr key={membershipFeePayment.product.id}>
          <td>{membershipFeePayment.product.shortName}</td>
          <td><CurrencyData value={membershipFeePayment.product.price} /></td>
          <td>
            {
              (membershipFeePayment.product.paymentDetails?.length ?? 0) === 1
                ? getDate(membershipFeePayment.product.paymentDetails?.at(0)?.payment?.date, DEFAULT_SHORT_DATE_TIME_FORMAT)
                : membershipFeePayment.product.paymentDetails?.map((x) => <p key={`${x.id}-paymentDate`}>{ getDate(x.payment?.date, DEFAULT_SHORT_DATE_TIME_FORMAT) }</p>)
            }
          </td>
          <td>
            {
              (membershipFeePayment.product.paymentDetails?.length ?? 0) === 1
                ? membershipFeePayment.product.paymentDetails?.at(0)?.payment.method?.value
                : membershipFeePayment.product.paymentDetails?.map((x) => <p key={`${x.id}-method`}>{ x.payment.method?.value }</p>)
            }
          </td>
          <td>
            {
              (membershipFeePayment.product.paymentDetails?.length ?? 0) === 1
                ? <CurrencyData value={membershipFeePayment.product.paymentDetails?.at(0)?.price ?? 0} />
                : membershipFeePayment.product.paymentDetails?.map(pd => <p key={`${pd.id}-price`}><CurrencyData value={pd.price} /></p>)
            }
          </td>
          <td>{getBooleanString(membershipFeePayment.isApplicable)}</td>
          <td className="text-center m-auto">{getCheckbox(membershipFeePayment)}</td>
        </tr>
      )
    );

    return (
      <tbody>{membershipFeesRows}</tbody>
    )
  }

  const getMembershipFeesYearsList = () => {
    let key = -1;
    const accordionItems = membershipFeesYears.map((year: string) => {
      key++;
      return (
        <Accordion.Item key={key.toString()} eventKey={key.toString()}>
          <Accordion.Header>{year}</Accordion.Header>
          <Accordion.Body>{getTable(year)}</Accordion.Body>
        </Accordion.Item>
      )
    });

    return (
      <Accordion defaultActiveKey="0" flush>
        {accordionItems}
      </Accordion>
    )
  }

  const getMembershipFeesToPayTable = () => {
    return (
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Cuota</th>
            <th>Valor original</th>
            <th>Valor actualizado</th>
            <th>Pago parcial</th>
          </tr>
        </thead>
        {getMembershipFeesToPayTableRows()}
      </Table>
    )
  }

  const getMembershipFeesToPayTableRows = () => {
    const membershipFeesRows = paymentsToMake.map((productPayment: ProductPayment) => {
      const amountToPay = productPayment.remainingPriceToPay;
      return (
        <tr key={productPayment.product.id}>
          <td>{productPayment.product.shortName}</td>
          <td><CurrencyData value={productPayment.product.price} /></td>
          <td>
          {
            partialPriceEnabled
              ? getPartialPriceInput(amountToPay)
              : <div>
                  <CurrencyData value={amountToPay} />
                  {`${productPayment.remainingPriceToPay !== productPayment.totalPriceToPay ? ' (pendiente)' : ''}`}
                </div>
          }
          </td>
          <td>
            <Form.Check key={productPayment.product.id} aria-label={productPayment.product.id}
              onChange={() => {
                setPartialPriceValue(amountToPay);
                setPartialPriceEnabled(!partialPriceEnabled);
                setPartialPayments(partialPayments.filter((pp) => pp.productId !== productPayment.product.id));
              }}/>
          </td>
        </tr>
      )
    });

    return (
      <tbody>{membershipFeesRows}</tbody>
    )
  }

  const getPartialPriceInput = (price: number) => {
    return (
      <div>
        <p className="mr-2 d-inline-block">$ </p>
        <div className="w-75 d-inline-block">
          <Form.Control
            type="number"
            step=".00"
            defaultValue={price}
            min={0}
            max={price}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => { handlePartialPriceChange(e) }}
          />
        </div>
      </div>
    )
  }

  const handlePartialPriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let price = Number(e.target.value);
    const defaultPrice = Number(e.target.defaultValue);
    if (price > defaultPrice) {
      e.target.value = e.target.defaultValue;
      price = Number(e.target.defaultValue);
    }
    if (price < 0) {
      e.target.value = "0";
      price = 0;
    }
    setPartialPriceValue(price);
  }

  const getTotal = () => {
    let total = 0;
    paymentsToMake.forEach((productPayment) => {
      const price = partialPriceEnabled ? partialPriceValue : productPayment.remainingPriceToPay;
      total = total + Number(price);
    });

    return total;
  }

  const handlePayButton = () => {
    if (partialPriceEnabled && partialPriceValue) {
      const lastItem = membershipFeesToPay.at(membershipFeesToPay.length - 1);
      if (!lastItem) return;

      setPartialPayments([...partialPayments, { productId: lastItem.id!, partialPayment: partialPriceValue }]);
    }
    setShowPaymentConfirmationPopup(true);
  }

  const getMembershipFeesToPaySection = () => {
    if (membershipFeesToPay.length === 0) {
      return <></>
    }

    return (
      <Row className="w-75">
        <Col>
          <h3>Cuotas a abonar</h3>
          {getMembershipFeesToPayTable()}
          <p className="fw-bold">TOTAL: <CurrencyData value={getTotal()} /></p>
          <Button className="justify-content-center" variant="primary" onClick={handlePayButton}>Pagar</Button>
        </Col>
      </Row>
    )
  }

  const operationCompleteCallback = async () => {
    await getMembershipFeesByMemberId();
    setPartialPriceEnabled(false);
    setPartialPriceValue(0);
    setPartialPayments([]);
    setMembershipFeesToPay([]);
    setPaymentsToMake([]);
  }

  return (
    <>
      <h3>Cuotas sociales</h3>
      <Row>
        {getMembershipFeesYearsList()}
      </Row>
      {getMembershipFeesToPaySection()}
      <PaymentPopup member={props.member} productFeesToPay={membershipFeesToPay} discriminator={MEMBERSHIP_FEE_DISCRIMINATOR}
                    show={showPaymentConfirmationPopup} setShow={setShowPaymentConfirmationPopup}
                    toastConfiguration={props.toastConfiguration} operationCompleteCallback={operationCompleteCallback}
                    partialPayments={partialPayments} paymentsToMake={paymentsToMake}
      />
    </>
  )
}
