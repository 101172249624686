import React, { useEffect } from 'react';
import {AuthContext} from "../context/auth-context";
import {LoginRequest} from "../models/web/login-request";
import {makePostLoginCall} from "../services/user-service";
import {HttpStatusCode} from "axios";
import {LoginResponse} from "../models/web/login-response";
import {UserLocalStorage} from "../models/user-local-storage";
import {useLocalStorage} from "./use-local-storage";
import {MEMBER_ROLE_ID} from "../constants";

export const useAuth = () => {
  const { user, setUser } = React.useContext(AuthContext);
  const { setItem, getItem, removeItem } = useLocalStorage();

  useEffect(() => {
    const user = getItem('user');
    if (user) {
      setUser(JSON.parse(user));
    }
  }, []);

  const login = async (loginRequest: LoginRequest): Promise<boolean> => {
    const response = await makePostLoginCall(loginRequest);
    if (!response.response || response?.statusCode !== HttpStatusCode.Ok) {
      console.log(`User ${loginRequest.username} cannot be logged in: ${response?.message}`);
      return false;
    }
    if (response.response.user.role.id === MEMBER_ROLE_ID) {
      console.log(`User ${loginRequest.username} cannot be logged in: invalid role`);
      return false;
    }
    const loginResponse = response.response;
    const userLocalStorage: UserLocalStorage = {
      id: loginResponse.user.id,
      role: loginResponse.user.role,
      token: loginResponse.token,
      username: loginResponse.user.username
    }
    setUser(userLocalStorage);
    setItem('user', JSON.stringify(userLocalStorage));
    return true;
  };

  const logout = async (): Promise<void> => {
    setUser(null);
    removeItem('user');
  };

  return { user, login, logout };
};
