import {AxiosResponse, HttpStatusCode} from "axios";
import axiosPrivate from "../models/axios/axios-private";
import {GET_SPORTS_ENDPOINT, PATCH_SPORTS_ENDPOINT, POST_SPORTS_ENDPOINT} from "../constants";
import {Sport} from "../models/sport";

export const makeGetSportsCall = async(): Promise<AxiosResponse | null> => {
  const response = await axiosPrivate.get(GET_SPORTS_ENDPOINT);
  if (response.status === HttpStatusCode.Ok) {
    return response;
  } else {
    console.log(
      `Error while trying to login sports: ${response.statusText}`
    );
    return null;
  }
};

export const makeDeleteSportCall = async(sportId: string): Promise<boolean> => {
  const response = await axiosPrivate.delete(PATCH_SPORTS_ENDPOINT.replace('{SportId}', sportId));
  if (response.status === HttpStatusCode.NoContent) {
    return new Promise((resolve) => resolve(true));
  } else {
    console.log(
      `Error while trying to delete sport SportId ${sportId}: ${response.statusText}`
    );
    return new Promise((resolve) => resolve(false));
  }
};


export const makePostSportsCall = async(request: Sport): Promise<AxiosResponse | null> => {
  const response = await axiosPrivate.post(POST_SPORTS_ENDPOINT, request);
  if (response.status === HttpStatusCode.Created) {
    return response;
  } else {
    console.log(
      `Error while trying to create sport: ${response.statusText}`
    );
    return null;
  }
};
