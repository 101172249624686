import {Button, Col, Form, FormGroup, InputGroup, OverlayTrigger, Popover, Row} from "react-bootstrap";
import FormInput from "./form-input";
import {useFormik} from "formik";
import {HttpStatusCode} from "axios";
import {ToastConfiguration} from "../../models/toast-configuration";
import {
  BUTTON_CREATE_NEW_LABEL,
  FORM_SELECT_DEFAULT_OPTION_TEXT, TOAST_FAILED_OPERATION_TITLE,
  TOAST_SUCCESSFUL_OPERATION_TITLE
} from "../../constants";
import {makePostUserCall} from "../../services/user-service";
import {makeGetModulesCall, makeGetRolesCall} from "../../services/authorization-service";
import { Role } from "../../models/role";
import {useEffect, useState} from "react";
import {KeyValuePair} from "../../models/key-value-pair";
import {useLoaderData} from "react-router-dom";
import {createUserSchema} from "../../models/validations/create-user-schema";

export interface Props {
  afterSubmitting: () => void;
  toastConfiguration?: ToastConfiguration | null;
}

export const getRolesLoader = async(): Promise<Role[]> => {
  return (await makeGetRolesCall()).response!;
};

export default function UserForm(props: Props) {
  const getRolesResponse = useLoaderData() as Role[];
  const [rolesKeyValueList, setRolesKeyValueList] = useState<KeyValuePair[]>([]);
  const [selectedRoleDescription, setSelectedRoleDescription] = useState("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
      passwordConfirmation: '',
      roleId: ''
    },
    validationSchema: createUserSchema,
    onSubmit: async () => {
      const response = await makePostUserCall({
        username: formik.values.username,
        password: formik.values.password,
        roleId: formik.values.roleId
      });
      if (response.statusCode === HttpStatusCode.Created) {
        props.toastConfiguration?.setOperationSuccessful(true);
        props.toastConfiguration?.setTitle(TOAST_SUCCESSFUL_OPERATION_TITLE);
        props.toastConfiguration?.setBody('El usuario ha sido cargado exitosamente.');
        formik.resetForm();
      }
      else {
        props.toastConfiguration?.setOperationSuccessful(false);
        props.toastConfiguration?.setTitle(TOAST_FAILED_OPERATION_TITLE);
        props.toastConfiguration?.setBody(response.message);
      }
      props.toastConfiguration?.setShow(true);
    }
  });

  useEffect(() => {
    const keyValueList: KeyValuePair[] = [ { key: '', value: FORM_SELECT_DEFAULT_OPTION_TEXT }];
    getRolesResponse.map((role) => keyValueList.push({ key: role.id as string, value: role.name }));
    setRolesKeyValueList(keyValueList);
  }, []);

  useEffect(() => {
    if (!formik.values.roleId) return;

    let description = getRolesResponse.find(x => x.id === formik.values.roleId)?.description;
    if (!description || description === '') {
      description = "(Sin descripción)";
    }
    setSelectedRoleDescription(description);
  }, [formik.values.roleId, getRolesResponse]);

  const popover = (
    <Popover id="popover-basic">
      <Popover.Header as="h3">Descripción</Popover.Header>
      <Popover.Body>{selectedRoleDescription}</Popover.Body>
    </Popover>
  )

  const showInfo = () => {
    if (!formik.values.roleId) return;

    return (
      <div className="d-flex justify-content-center">
        <OverlayTrigger trigger="click" placement="right" overlay={popover} rootClose={true}>
          <Button variant="success"><i className="fa-solid fa-circle-question"></i></Button>
        </OverlayTrigger>
      </div>
    )
  }

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Row>
        <Col md={4}>
          <FormInput
            controlId="username"
            label="Nombre de usuario"
            type="text"
            name="username"
            value={formik.values.username}
            onChangeEvent={formik.handleChange}
            onBlurEvent={formik.handleBlur}
            touchedField={formik.touched.username}
            errorField={formik.errors.username}
            placeholder=""
            isDisabled={false}
          />
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <FormInput
            controlId="password"
            label="Contraseña"
            type={ showPassword ? 'text' : 'password' }
            name="password"
            value={formik.values.password}
            onChangeEvent={formik.handleChange}
            onBlurEvent={formik.handleBlur}
            touchedField={formik.touched.password}
            errorField={formik.errors.password}
            placeholder=""
            isDisabled={false}
            addonButton={<Button onClick={() => setShowPassword(!showPassword)} variant="outline-primary" id="button-addon2">{showPassword ? 'Ocultar' : 'Mostrar'}</Button>}
          />
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <FormInput
            controlId="passwordConfirmation"
            label="Confirmar contraseña"
            type={ showPasswordConfirmation ? 'text' : 'password' }
            name="passwordConfirmation"
            value={formik.values.passwordConfirmation}
            onChangeEvent={formik.handleChange}
            onBlurEvent={formik.handleBlur}
            touchedField={formik.touched.passwordConfirmation}
            errorField={formik.errors.passwordConfirmation}
            placeholder=""
            isDisabled={false}
            addonButton={<Button onClick={() => setShowPasswordConfirmation(!showPasswordConfirmation)} variant="outline-primary" id="button-addon2">{showPasswordConfirmation ? 'Ocultar' : 'Mostrar'}</Button>}
          />
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <FormInput
            controlId="roleId"
            label="Rol"
            type="select"
            name="roleId"
            value={formik.values.roleId}
            onChangeEvent={formik.handleChange}
            onBlurEvent={formik.handleBlur}
            touchedField={formik.touched.roleId}
            errorField={formik.errors.roleId}
            placeholder=""
            isDisabled={false}
            selectOptions={rolesKeyValueList}
          />
        </Col>
        <Col md={1} style={{alignContent: "center"}}>
          { showInfo() }
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <div className="d-md-flex justify-content-center">
            <Button variant="primary" type="submit">{BUTTON_CREATE_NEW_LABEL}</Button>
          </div>
        </Col>
      </Row>
    </Form>
  )
}
