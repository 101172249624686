import {Link, useLoaderData} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Button, Row, Table} from "react-bootstrap";
import {Payment} from "../../models/payment";
import {makeGetPaymentCall, makeGetPaymentsCall} from "../../services/payment-service";
import {GetPagedResponse} from "../../models/web/get-paged-response";
import CurrencyData from "../../components/currency-data";
import PaymentVerificationPopup from "../../components/payment-verification-popup";
import {makeGetValuesListsCall} from "../../services/values-lists-service";
import {LOOKUP_LIST_ID_PAYMENT_VERIFICATION_STATUS, TOAST_SUCCESSFUL_OPERATION_TITLE} from "../../constants";
import {LookupListValue} from "../../models/lookup-list-value";
import {KeyValuePair} from "../../models/key-value-pair";
import ConfirmationPopup from "../../components/confirmation-popup";
import {HttpStatusCode} from "axios/index";
import {ToastConfiguration} from "../../models/toast-configuration";
import {useToastConfiguration} from "../../App";

export const listPaymentsLoader = async(): Promise<Payment[] | null> => {
  return (await makeGetPaymentsCall({ isCompleted: false, includeOnlyManualPayments: true })).response;
};

export default function ListPayments() {
  const [pendingPayments, setPendingPayments] = useState(useLoaderData() as Payment[]);
  const { toastConfiguration } = useToastConfiguration();
  const [statusKeyValueList, setStatusKeyValueList] = useState<KeyValuePair[]>([]);
  const [payment, setPayment] = useState<Payment>();
  const [showPopup, setShowPopup] = useState(false);
  const [getUrlPaymentId, setGetUrlPaymentId] = useState<string | null>(null);
  const [paymentsUrls, setPaymentUrls] = useState<KeyValuePair[]>([]);

  useEffect(() => {
    const getValuesLists = async () => {
      const statusListValues = (await makeGetValuesListsCall(LOOKUP_LIST_ID_PAYMENT_VERIFICATION_STATUS))?.data[0].lookupListValues as LookupListValue[];
      let list: KeyValuePair[] = [];
      statusListValues.map(status => list.push({key: status.id as string, value: status.value}));
      setStatusKeyValueList(list);
    }

    getValuesLists().then();
  }, []);

  // Table
  const getPendingManualCheckPaymentsTable = (): JSX.Element => {
    if (!pendingPayments || pendingPayments.length === 0) {
      return <p>No hay pagos pendientes de verificación</p>
    }

    return (
      <Table responsive striped bordered hover>
        <thead>
        <tr>
          <th>N° socio</th>
          <th>Nombre completo</th>
          <th>Concepto</th>
          <th>Importe original</th>
          <th>Importe actualizado</th>
          <th>Estado</th>
          <th>Comprobante</th>
          <th>Opciones</th>
        </tr>
        </thead>
        {getPendingManualCheckPaymentsTableRows()}
      </Table>
    )
  }

  const getPaymentOriginalTotal = (payment: Payment): number => {
    let total = 0;
    payment.paymentDetails?.forEach(detail => {
      total += detail.product?.price ?? 0;
    });
    return total;
  }

  const getPaymentUpdatedTotal = (payment: Payment): number => {
    let total = 0;
    payment.paymentDetails?.forEach(detail => {
      total += detail.price;
    });
    return total;
  }

  const handleVerificateButtonClick = (payment: Payment) => {
    setShowPopup(true);
    setPayment(payment);
  }

  useEffect(() => {
    const callGetLink = async() => { await getLink(getUrlPaymentId ?? "") }

    if (getUrlPaymentId) {
      callGetLink().then(_ => setGetUrlPaymentId(null));
    }
  }, [getUrlPaymentId]);

  const getLink = async (paymentId: string): Promise<void> => {
    const url = (await makeGetPaymentCall(paymentId)).response?.bankTransferReceiptPath ?? "";
    const existingEntry = paymentsUrls.find(x => x.key === paymentId);
    if (existingEntry) {
      existingEntry.value = url;
    }
    else {
      paymentsUrls.push({ key: paymentId, value: url });
    }
    setPaymentUrls(paymentsUrls);
  }

  const getLinkFromPayment = (paymentId: string): string | null => {
    return paymentsUrls.find(x => x.key === paymentId)?.value ?? null;
  }

  const getDownloadTextOrButton = (paymentId: string) => {
    const link = getLinkFromPayment(paymentId);
    return link
      ? (
        <a href={link}>
          <Button><i className="fas fa-download"/></Button>
        </a>
      )
      : (
        <Button type="button" className="ml-2 mr-3" onClick={() => setGetUrlPaymentId(paymentId)}>Obtener</Button>
      )
  }

  const getPendingManualCheckPaymentsTableRows = (): JSX.Element => {
    if (!pendingPayments) {
      return <></>
    }

    const paymentsRows = pendingPayments.map((payment: Payment) => (
      <tr key={payment.id}>
        <td>{payment.member?.memberNumber}</td>
        <td>{payment.member?.firstName} {payment.member?.lastName}</td>
        <td><ul>{payment.paymentDetails?.map(detail => <li key={detail.product?.id}>{detail.product?.name}</li>) }</ul></td>
        <td><CurrencyData value={getPaymentOriginalTotal(payment)}/></td>
        <td><CurrencyData value={getPaymentUpdatedTotal(payment)}/></td>
        <td>{payment.manualCheckStatus?.value}</td>
        <td>
          <div className="d-flex justify-content-center">
            {getDownloadTextOrButton(payment.id)}
          </div>
        </td>
        <td>
          <div className="d-flex justify-content-center">
            <Button type="button" className="ml-2 mr-3" onClick={() => handleVerificateButtonClick(payment)}>Verificar</Button>
          </div>
        </td>
      </tr>
    ));

    return (
      <tbody>{paymentsRows}</tbody>
    )
  }

  const getPendingPayments = async () => {
    const payments = (await makeGetPaymentsCall({ isCompleted: false, includeOnlyManualPayments: true })).response as Payment[];
    setPendingPayments(payments);
  }

  return (
    <Row>
      <h2>Pagos</h2>
      <h3>Pendientes de verificación</h3>
      {getPendingManualCheckPaymentsTable()}
      {
        payment
          ? <PaymentVerificationPopup payment={payment!} show={showPopup} setShow={setShowPopup} operationCompleteCallback={getPendingPayments} statusListValues={statusKeyValueList} toastConfiguration={toastConfiguration}/>
          : <></>
      }
    </Row>
  )
}
