import {makeGetMemberByIdCall} from "../../services/member-service";
import {useLoaderData} from "react-router-dom";
import {Member} from "../../models/member/member";
import {Col, Container, Row, Tab, Tabs, Toast, ToastContainer} from "react-bootstrap";
import MemberForm from "../../components/forms/member-form";
import {Operation} from "../../models/operation";
import MemberMembershipFees from "../../components/members/member-membership-fees";
import MemberActivities from "../../components/members/member-activities";
import {useToastConfiguration} from "../../App";

// @ts-ignore
export const viewMemberLoader = async({ params }): Promise<void> => {
  return (await makeGetMemberByIdCall(params.memberId))?.data;
};

export default function ViewMember() {
  const member = useLoaderData() as Member;
  const { toastConfiguration } = useToastConfiguration();

  return (
    <Row>
      <Col md={{ span: 10, offset: 1 }}>
        <h2>Ficha del socio</h2>
        <p>Ficha del socio N°{member.memberNumber}.</p>
        <Tabs
          defaultActiveKey="file"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab key="form" eventKey="file" title="Ficha">
            <MemberForm member={member} operation={Operation.View} toastConfiguration={toastConfiguration} />
          </Tab>
          <Tab key="membershipFees" eventKey="membershipFees" title="Cuotas sociales">
            <MemberMembershipFees member={member} toastConfiguration={toastConfiguration} />
          </Tab>
          <Tab key="activities" eventKey="activities" title="Actividades">
            <MemberActivities member={member} toastConfiguration={toastConfiguration} />
          </Tab>
        </Tabs>
      </Col>
    </Row>
  )
}
