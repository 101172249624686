import {Col, Container, Row} from "react-bootstrap";
import MemberForm from "../../components/forms/member-form";
import {makeGetMemberByIdCall} from "../../services/member-service";
import {useLoaderData} from "react-router-dom";
import {Member} from "../../models/member/member";
import {Operation} from "../../models/operation";
import {useToastConfiguration} from "../../App";
import { BUTTON_CONFIRM_UPDATE_LABEL } from "../../constants";
import {useState} from "react";

// @ts-ignore
export const editMemberLoader = async({ params }): Promise<void> => {
  return (await makeGetMemberByIdCall(params.memberId))?.data;
};

export default function EditMember() {
  const { toastConfiguration } = useToastConfiguration();
  const [member, setMember] = useState<Member>(useLoaderData() as Member);

  return (
    <Row>
      <Col md={{ span: 9, offset: 2 }}>
        <h2>Editar socio</h2>
        <p>Edite los datos del socio y, cuando finalice, haga click en el botón "${BUTTON_CONFIRM_UPDATE_LABEL}".</p>
        <MemberForm member={member} operation={Operation.Edit} toastConfiguration={toastConfiguration} />
      </Col>
    </Row>
  )
}
