import {AxiosResponse, HttpStatusCode} from "axios";
import axiosPrivate from "../models/axios/axios-private";
import {
  GET_PAYMENT_ENDPOINT,
  GET_PAYMENTS_ENDPOINT,
  POST_PAYMENTS_ENDPOINT, PUT_PAYMENTS_ENDPOINT
} from "../constants";
import {CreatePayment} from "../models/web/create-payment";
import {HttpRequestResponse} from "../models/axios/http-request-response";
import {AxiosError} from "axios";
import {getEndpointResponse} from "../models/endpoint-response";
import {Payment} from "../models/payment";
import {GetPagedResponse} from "../models/web/get-paged-response";
import {PaymentDto} from "../models/dtos/payment-dto";

export const makePostPaymentsCall = async(request: CreatePayment): Promise<AxiosResponse | null> => {
  const response = await axiosPrivate.post(POST_PAYMENTS_ENDPOINT, request);
  if (response.status === HttpStatusCode.Created) {
    return response;
  } else {
    console.log(
      `Error while trying to create payment: ${response.statusText}`
    );
    return null;
  }
};

export const makePutPaymentsCall = async(payment: PaymentDto): Promise<HttpRequestResponse<Payment>> => {
  let response: AxiosResponse | AxiosError;
  try {
    let url = PUT_PAYMENTS_ENDPOINT.replace('{PaymentId}', payment.id);
    response = await axiosPrivate.put(url, payment);
  } catch (err: unknown) {
    response = err as AxiosError;
  }
  return getEndpointResponse(response);
};

export const makeGetPaymentCall = async(id: string): Promise<HttpRequestResponse<Payment>> => {
  let response: AxiosResponse | AxiosError;
  try {
    let url = GET_PAYMENT_ENDPOINT.replace('{PaymentId}', id);
    response = await axiosPrivate.get(url);
  } catch (err: unknown) {
    response = err as AxiosError;
  }
  return getEndpointResponse(response);
};


export const makeGetPaymentsCall = async(options?: GetPaymentsOptions): Promise<HttpRequestResponse<Payment[]>> => {
  let response: AxiosResponse | AxiosError;
  try {
    let url = GET_PAYMENTS_ENDPOINT;
    if (options) {
      url = url.concat('?');
      url = options.isCompleted != null ? url.concat('&isCompleted=', String(options.isCompleted)) : url;
      url = options.includeOnlyManualPayments != null ? url.concat('&includeOnlyManualPayments=', String(options.includeOnlyManualPayments)) : url;
    }
    response = await axiosPrivate.get(url);
  } catch (err: unknown) {
    response = err as AxiosError;
  }
  return getEndpointResponse(response);
};

interface GetPaymentsOptions {
  isCompleted?: boolean | null;
  includeOnlyManualPayments?: boolean | null;
}
