import {Button, Form, Modal} from "react-bootstrap";
import {
  SUCCESSFULLY_VERIFIED_STATUS_ID, TOAST_SUCCESSFUL_OPERATION_TITLE
} from "../constants";
import {getMemberFullName} from "../helpers";
import React, {Dispatch, SetStateAction, useState} from "react";
import {ToastConfiguration} from "../models/toast-configuration";
import {Payment} from "../models/payment";
import CurrencyData from "./currency-data";
import {KeyValuePair} from "../models/key-value-pair";
import {makePutPaymentsCall} from "../services/payment-service";
import {PaymentDto} from "../models/dtos/payment-dto";
import {HttpStatusCode} from "axios";
import {listPaymentsLoader} from "../pages/payments/list-payments";

export interface Props {
  payment: Payment;
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  toastConfiguration?: ToastConfiguration | null;
  operationCompleteCallback: () => void;
  statusListValues: KeyValuePair[];
}

export default function PaymentVerificationPopup(props: Props) {
  const [selectedStatusId, setSelectedStatusId] = useState<string | null>(null);
  const [comments, setComments] = useState<string | null>(null);

  const handleSubmit = async(e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!selectedStatusId) return;

    let paymentDto: PaymentDto = {
      id: props.payment.id,
      manualCheckStatusId: selectedStatusId,
      isCompleted: selectedStatusId === SUCCESSFULLY_VERIFIED_STATUS_ID,
      comments: comments
    }

    const response = await makePutPaymentsCall(paymentDto);
    props.toastConfiguration?.setShow(true);
    if (response.statusCode === HttpStatusCode.Ok) {
      props.toastConfiguration?.setOperationSuccessful(true);
      props.toastConfiguration?.setTitle(TOAST_SUCCESSFUL_OPERATION_TITLE);
      props.toastConfiguration?.setBody('El estado del pago ha sido modificado éxitosamente.');
      props.setShow(false);
      props.operationCompleteCallback();
    }
  }

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedStatusId(e.target.value);
  }

  const handleCommentsChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComments(e.target.value);
  }

  const getPaymentTotal = (payment: Payment): number => {
    let total = 0;
    payment.paymentDetails?.forEach(detail => {
      total += detail.price;
    });
    return total;
  }

  return (
    <Modal show={props.show} onHide={() => props.setShow(false)}>
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Verificar comprobante</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Socio: {getMemberFullName(props.payment.member!)} (N° {props.payment.member!.memberNumber}).</p>
          <p>Conceptos:</p>
          <ul>{props.payment.paymentDetails?.map(detail => <li key={detail.product?.id}>{detail.product?.name} (<CurrencyData value={detail.price}/>)</li>) }</ul>
          <p>Total: <CurrencyData value={getPaymentTotal(props.payment)}/></p>
          <Form.Label htmlFor="status">Estado</Form.Label>
          <Form.Select id="status" aria-label="Seleccione un estado" onChange={handleSelectChange}>
            {
              props.statusListValues.map(status => <option key={status.key} value={status.key}>{status.value}</option>)
            }
          </Form.Select>
          <Form.Label htmlFor="comments">Comentarios</Form.Label>
          <Form.Control
            type="textarea"
            id="comments"
            aria-describedby="comments"
            onChange={handleCommentsChange}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={async () => {
            props.setShow(false);
          }}>Cancelar</Button>
          <Button variant="primary" type="submit" disabled={!selectedStatusId}>Confirmar</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}
