import {Image} from "react-bootstrap";
import {useAuth} from "../hooks/use-auth";
import {Link} from "react-router-dom";
import {
  ADD_MEMBER_TASK_NAME,
  LIST_MEMBER_TASK_NAME,
  MEMBERSHIP_FEES_MODULE_NAME,
  MEMBERS_MODULE_NAME,
  SPORTS_MODULE_NAME,
  ACTIVITIES_MODULE_NAME,
  LIST_ACTIVITY_TASK_NAME,
  ADD_ACTIVITY_TASK_NAME,
  REPORTS_MODULE_NAME,
  USERS_MODULE_NAME,
  ROLES_MODULE_NAME,
  SETTINGS_MODULE_NAME,
  ADD_USERS_TASK_NAME, ADD_ROLES_TASK_NAME, VIEW_VALUE_LIST_TASK_NAME, LIST_ROLES_TASK_NAME, LIST_USERS_TASK_NAME, PAYMENTS_MODULE_NAME
} from "../constants";
import { router } from "../router";

export default function Sidebar() {
  const { user } = useAuth();

  return (
    <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">
      <a className="sidebar-brand d-flex align-items-center justify-content-center" href="#">
        <div className="sidebar-brand-icon">
          <Image src={`${router.basename}/pacifico.png`} width={75}/>
        </div>
        <div className="sidebar-brand-text mx-3">C.A. Pacífico</div>
      </a>

      <hr className="sidebar-divider my-0" />

      <li className="nav-item">
        <Link className="nav-link" to={`/home`}>
          <i className="fas fa-fw fa-home"></i>
          <span>Inicio</span>
        </Link>
      </li>

      {
        user?.role.tasks.some((task) => task.module.name === MEMBERS_MODULE_NAME || task.module.name === MEMBERSHIP_FEES_MODULE_NAME)
          ? (
            <>
              <hr className="sidebar-divider" />
              <div className="sidebar-heading">Socios</div>
            </>
          )
          : <></>
      }

      {
        user?.role.tasks.some((task) => task.module.name === MEMBERS_MODULE_NAME)
          ? (
            <li className="nav-item">
              <a className="nav-link collapsed" data-toggle="collapse" data-target="#collapseMembers" aria-expanded="true" aria-controls="collapseMembers">
                <i className="fas fa-fw fa-users"></i>
                <span>Socios</span>
              </a>
              <div id="collapseMembers" className="collapse" aria-labelledby="headingMembers" data-parent="#accordionSidebar">
                <div className="bg-white py-2 collapse-inner rounded">
                  <h6 className="collapse-header">Socios:</h6>
                  {
                    user?.role.tasks.find((task) => task.name === LIST_MEMBER_TASK_NAME)
                      ? <Link className="collapse-item" to={`/members/list`}>Listar</Link>
                      : <></>
                  }
                  {
                    user?.role.tasks.find((task) => task.name === ADD_MEMBER_TASK_NAME)
                      ? <Link className="collapse-item" to={`/members/add`}>Cargar</Link>
                      : <></>
                  }
                </div>
              </div>
            </li>
          )
          : <></>
      }

      {
        user?.role.tasks.some((task) => task.module.name === MEMBERSHIP_FEES_MODULE_NAME)
          ? (
            <li className="nav-item">
              <a className="nav-link collapsed" data-toggle="collapse" data-target="#collapseMembershipFees" aria-expanded="true" aria-controls="collapseMembershipFees">
                <i className="fa-regular fa-calendar"></i>
                <span>Cuotas sociales</span>
              </a>
              <div id="collapseMembershipFees" className="collapse" aria-labelledby="headingMembershipFees" data-parent="#accordionSidebar">
                <div className="bg-white py-2 collapse-inner rounded">
                  <h6 className="collapse-header">Cuotas sociales:</h6>
                  {
                    user?.role.tasks.find((task) => task.name === LIST_MEMBER_TASK_NAME)
                      ? <Link className="collapse-item" to={`/membership-fees/list`}>Listar</Link>
                      : <></>
                  }
                  {
                    user?.role.tasks.find((task) => task.name === LIST_MEMBER_TASK_NAME)
                      ? <Link className="collapse-item" to={`/membership-fees/add`}>Cargar</Link>
                      : <></>
                  }
                </div>
              </div>
            </li>
          )
          : <></>
      }

      {
        user?.role.tasks.some((task) => task.module.name === PAYMENTS_MODULE_NAME)
          ? (
            <li className="nav-item">
              <Link className="nav-link" to={`/payments/list`}>
                <i className="fa-regular fa-credit-card"></i>
                <span>Pagos</span>
              </Link>
            </li>
          )
          : <></>
      }

      {
        user?.role.tasks.some((task) => task.module.name === SPORTS_MODULE_NAME || task.module.name === ACTIVITIES_MODULE_NAME)
          ? (
            <>
              <hr className="sidebar-divider" />
              <div className="sidebar-heading">Deportes y actividades</div>
            </>
          )
          : <></>
      }

      {
        user?.role.tasks.some((task) => task.module.name === SPORTS_MODULE_NAME)
          ? (
            <li className="nav-item">
              <Link className="nav-link" to={`/sports`}>
                <i className="fa-regular fa-futbol"></i>
                <span>Deportes</span>
              </Link>
            </li>
          )
          : <></>
      }

      {
        user?.role.tasks.some((task) => task.module.name === ACTIVITIES_MODULE_NAME)
          ? (
            <>
              <li className="nav-item">
                <a className="nav-link collapsed" data-toggle="collapse" data-target="#collapseActivities"
                   aria-expanded="true" aria-controls="collapseActivities">
                  <i className="fa-solid fa-list-check"></i>
                  <span>Actividades</span>
                </a>
                <div id="collapseActivities" className="collapse" aria-labelledby="headingActivities" data-parent="#accordionSidebar">
                  <div className="bg-white py-2 collapse-inner rounded">
                    <h6 className="collapse-header">Actividades:</h6>
                    {
                      user?.role.tasks.find((task) => task.name === LIST_ACTIVITY_TASK_NAME)
                        ? <Link className="collapse-item" to={`/activities/list`}>Listar</Link>
                        : <></>
                    }
                    {
                      user?.role.tasks.find((task) => task.name === ADD_ACTIVITY_TASK_NAME)
                        ? <Link className="collapse-item" to={`/activities/add`}>Cargar</Link>
                        : <></>
                    }
                  </div>
                </div>
              </li>
              <li className="nav-item">
                <a className="nav-link collapsed" data-toggle="collapse" data-target="#collapseActivitiesFees"
                   aria-expanded="true" aria-controls="collapseActivitiesFees">
                  <i className="fa-solid fa-dollar-sign"></i>
                  <span>Aranceles</span>
                </a>
                <div id="collapseActivitiesFees" className="collapse" aria-labelledby="headingActivitiesFees" data-parent="#accordionSidebar">
                  <div className="bg-white py-2 collapse-inner rounded">
                    <h6 className="collapse-header">Aranceles:</h6>
                    {
                      user?.role.tasks.find((task) => task.name === LIST_ACTIVITY_TASK_NAME)
                        ? <Link className="collapse-item" to={`/activities/fees/list`}>Listar</Link>
                        : <></>
                    }
                    {
                      user?.role.tasks.find((task) => task.name === ADD_ACTIVITY_TASK_NAME)
                        ? <Link className="collapse-item" to={`/activities/fees/add`}>Cargar</Link>
                        : <></>
                    }
                  </div>
                </div>
              </li>
            </>
          )
          : <></>
      }

      {
        user?.role.tasks.some((task) => task.module.name === REPORTS_MODULE_NAME)
          ? (
            <>
              <hr className="sidebar-divider" />
              <div className="sidebar-heading">Administración</div>
              <li className="nav-item">
                <Link className="nav-link" to={`/admin/reports`}>
                  <i className="fa fa-bar-chart-o"></i>
                  <span>Reportes</span>
                </Link>
              </li>
            </>
          )
          : <></>
      }

      {
        user?.role.tasks.some((task) => task.module.name === USERS_MODULE_NAME || task.module.name === ROLES_MODULE_NAME || task.module.name === SETTINGS_MODULE_NAME)
          ? (
            <>
              <hr className="sidebar-divider" />
              <div className="sidebar-heading">Configuración</div>
            </>
          )
          : <></>
      }

      {
        user?.role.tasks.some((task) => task.module.name === USERS_MODULE_NAME)
          ? (
            <li className="nav-item">
              <a className="nav-link collapsed" data-toggle="collapse" data-target="#collapseUsers"
                 aria-expanded="true" aria-controls="collapseUsers">
                <i className="fa-solid fa-users-gear"></i>
                <span>Usuarios</span>
              </a>
              <div id="collapseUsers" className="collapse" aria-labelledby="headingUsers" data-parent="#accordionSidebar">
                <div className="bg-white py-2 collapse-inner rounded">
                  <h6 className="collapse-header">Usuarios:</h6>
                  {
                    user?.role.tasks.find((task) => task.name === LIST_USERS_TASK_NAME)
                      ? <Link className="collapse-item" to={`/settings/users/list`}>Listar</Link>
                      : <></>
                  }
                  {
                    user?.role.tasks.find((task) => task.name === ADD_USERS_TASK_NAME)
                      ? <Link className="collapse-item" to={`/settings/users/add`}>Cargar</Link>
                      : <></>
                  }
                </div>
              </div>
            </li>
          )
          : <></>
      }

      {
        user?.role.tasks.some((task) => task.module.name === ROLES_MODULE_NAME)
          ? (
            <li className="nav-item">
              <a className="nav-link collapsed" data-toggle="collapse" data-target="#collapseRoles"
                 aria-expanded="true" aria-controls="collapseRoles">
                <i className="fa-solid fa-elevator"></i>
                <span>Roles</span>
              </a>
              <div id="collapseRoles" className="collapse" aria-labelledby="headingRoles" data-parent="#accordionSidebar">
                <div className="bg-white py-2 collapse-inner rounded">
                  <h6 className="collapse-header">Roles:</h6>
                  {
                    user?.role.tasks.find((task) => task.name === LIST_ROLES_TASK_NAME)
                      ? <Link className="collapse-item" to={`/settings/roles/list`}>Listar</Link>
                      : <></>
                  }
                  {
                    user?.role.tasks.find((task) => task.name === ADD_ROLES_TASK_NAME)
                      ? <Link className="collapse-item" to={`/settings/roles/add`}>Cargar</Link>
                      : <></>
                  }
                </div>
              </div>
            </li>
          )
          : <></>
      }

      {
        user?.role.tasks.find((task) => task.name === VIEW_VALUE_LIST_TASK_NAME)
          ? (
            <li className="nav-item">
              <Link className="nav-link" to={`/settings/values`}>
                <i className="fas fa-list"></i>
                <span>Listas de valores</span>
              </Link>
            </li>
          )
          : <></>
      }

      <hr className="sidebar-divider" />

      <hr className="sidebar-divider d-none d-md-block" />

      <div className="text-center d-none d-md-inline">
        <button className="rounded-circle border-0" id="sidebarToggle"></button>
      </div>
    </ul>
  )
}
