import {useToastConfiguration} from "../../App";
import {useState} from "react";
import {PeriodicProduct} from "../../models/periodic-product";
import {useLoaderData} from "react-router-dom";
import {Col, Row} from "react-bootstrap";
import {BUTTON_CONFIRM_UPDATE_LABEL} from "../../constants";
import ProductForm from "../../components/forms/product-form";
import {Operation} from "../../models/operation";
import {makeGetProductByIdCall} from "../../services/product-service";

// @ts-ignore
export const editActivityFeeLoader = async({ params }): Promise<PeriodicProduct | undefined> => {
  return (await makeGetProductByIdCall(params.activityFeeId))?.data;
};

export default function EditActivityFee() {
  const { toastConfiguration } = useToastConfiguration();
  const [product, setProduct] = useState<PeriodicProduct>(useLoaderData() as PeriodicProduct);

  return (
    <Row>
      <Col md={{ offset: 2, span: 8 }}>
        <h2>Editar arancel de actividad</h2>
        <p>Edite los datos del arancel correspondiente a "<p className="font-weight-bold">{product.name}</p>" y, cuando finalice, haga click en el botón "{BUTTON_CONFIRM_UPDATE_LABEL}".</p>
        <ProductForm product={product} discriminator="ActivityFee" operation={Operation.Edit} toastConfiguration={toastConfiguration}/>
      </Col>
    </Row>
  )
}
