import axios from 'axios';

export const getToken = (): string | null => {
  return sessionStorage.getItem('token') ?? null;
};

const axiosPrivate = axios.create({
  headers: {
    "Content-Type": "application/json"
  }
});

axiosPrivate.interceptors.request.use(
  async (config) => {
    const token = getToken();

    if (token !== null) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  },
  async (error) => await Promise.reject(error)
)

export default axiosPrivate;
