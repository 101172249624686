import {useAuth} from "../hooks/use-auth";
import {Navigate, useLocation} from "react-router-dom";
import React from "react";
import {LOGIN_PAGE_ROUTE} from "../constants";

export interface Props {
  children: JSX.Element
}

export default function ProtectedRoute(props: Props): JSX.Element {
  const { user } = useAuth();
  const location = useLocation();

  if (!user) {
    return <Navigate to={LOGIN_PAGE_ROUTE} state={{ from: location }} replace />;
  }

  return props.children;
}
