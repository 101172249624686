import {Button, Col, Form, Modal, Pagination, Row, Table, Toast, ToastContainer} from "react-bootstrap";
import {Member} from "../../models/member/member";
import {makeGetMembersCall, MemberSearch, makePatchMembersCallV2} from "../../services/member-service";
import {Link, useLoaderData, useNavigate, useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {MemberDto} from "../../models/member/member-dto";
import {GetPagedResponse} from "../../models/web/get-paged-response";
import {MemberStatusId} from "../../models/member/member-status-id";
import {MemberStatusUpdateReason} from "../../models/member/member-status-update-reason";
import {DEFAULT_SHORT_ISO_DATE_FORMAT, MEMBER_LIST_SIZE } from "../../constants";
import ExportToExcel from "../../components/export-to-excel";
import { getDate } from "../../helpers";

export const listMembersLoader = async(): Promise<void> => {
  return (await makeGetMembersCall(MEMBER_LIST_SIZE))?.data;
};

function ListMembers() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [pageNumber, setPageNumber] = useState<number>(Number(searchParams.get('page') ?? 1));
  const getMembersResponse = useLoaderData() as GetPagedResponse<Member>;
  const [membersResponse, setMembersResponse] = useState<GetPagedResponse<Member>>(getMembersResponse);
  const [selectedMemberId, setSelectedMemberId] = useState<string>();
  const [searchCriteria, setSearchCriteria] = useState<string>('firstNameLastName');
  const [searchValue, setSearchValue] = useState<string>('');
  const [dropMemberReason, setDropMemberReason] = useState<string>();
  const [dropMemberEffectiveDate, setDropMemberEffectiveDate] = useState<string>();
  const [dropMemberComments, setDropMemberComments] = useState<string>();
  const [showDropMemberConfirmationModal, setShowDropMemberConfirmationModal] = useState(false);
  const [showDropMemberToast, setShowDropMemberToast] = useState(false);
  const [showInactiveMembers, setShowInactiveMembers] = useState(false);

  // Effects
  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      setPageNumber(1);
      await performMemberSearch();
    }, 800);

    return () => clearTimeout(delayDebounceFn)
  }, [searchCriteria, searchValue]);

  useEffect(() => {
    const callGetMembers = async () => {
      const membersResponse = await makeGetMembersCall(MEMBER_LIST_SIZE, Number(pageNumber!), showInactiveMembers);
      setMembersResponse(membersResponse?.data);
    }

    const callPerformMemberSearch = async () => {
      await performMemberSearch();
    }

    if (searchCriteria && searchValue) {
      callPerformMemberSearch().then();
    }
    else {
      callGetMembers().then();
    }
  }, [showInactiveMembers, pageNumber]);

  // Member search
  const getMemberSearch = (): JSX.Element => {
    return (
      <Form>
        <Form.Group className="xs-3" controlId="formGroupSearchType">
          <Form.Label>Criterio de búsqueda</Form.Label>
          <Form.Select aria-label="Test" onChange={handleSearchCriteriaChange}>
            <option>Seleccione un criterio de búsqueda</option>
            <option value="firstNameLastName" selected={true}>Nombre y apellido</option>
            <option value="memberNumber">Número de socio</option>
            <option value="documentNumber">Número de documento</option>
          </Form.Select>
        </Form.Group>
        <Form.Group className="xs-3" controlId="formGroupInput">
          <Form.Label>Valor de búsqueda</Form.Label>
          <Form.Control type="text" placeholder="Ingrese un valor" value={searchValue} onChange={handleSearchValueChange} />
        </Form.Group>
      </Form>
    )
  }

  const performMemberSearch = async (): Promise<void> => {
    const memberSearch: MemberSearch = {
      documentNumber: searchCriteria === 'documentNumber' ? Number(searchValue) : undefined,
      memberNumber: searchCriteria === 'memberNumber' ? Number(searchValue) : undefined,
      fullName: searchCriteria === 'firstNameLastName' ? searchValue : undefined,
    }
    const searchResult = await makeGetMembersCall(MEMBER_LIST_SIZE, Number(pageNumber!), showInactiveMembers, memberSearch);

    setMembersResponse(searchResult?.data);
  }

  // Filters
  const getFilters = (): JSX.Element => {
    return (
      <Form>
        <Form.Group className="xs-3" controlId="formGroupSearchType">
          <Form.Check type="checkbox" label="Incluir socios inactivos" onChange={handleInactiveMembersCheckboxChange} />
        </Form.Group>
      </Form>
    )
  }

  // Table
  const getTable = (): JSX.Element => {
    return (
      <Table responsive striped bordered hover>
        <thead>
        <tr>
          <th>N° socio</th>
          <th>Nombres</th>
          <th>Apellidos</th>
          <th>N° Documento</th>
          <th>Tipo</th>
          <th>Estado</th>
          <th>Opciones</th>
        </tr>
        </thead>
        {getTableRows()}
      </Table>
    )
  }

  const getTableRows = (): JSX.Element => {
    if (!getMembersResponse) {
      return <></>
    }

    const membersRows = membersResponse.results.map((member: Member) => (
      <tr key={member.id}>
        <td>{member.memberNumber}</td>
        <td>{member.firstName}</td>
        <td>{member.lastName}</td>
        <td>{member.documentNumber}</td>
        <td>{member.memberType.value}</td>
        <td>{member.status.value}</td>
        <td>
          <div className="d-flex justify-content-center">
            <Button type="button" variant="primary" className="ml-2 mr-3">
              <Link to={`../view/${member.id}`} className="link-button-primary">Ver ficha</Link>
            </Button>
            <Button type="button" variant="primary" className="ml-2 mr-3">
              <Link to={`../edit/${member.id}`} className="link-button-primary">Editar</Link>
            </Button>
            <Button type="button" variant="danger" className="ml-2 mr-3" value={member.id!} onClick={handleDropMemberConfirmationModalShow}>Dar de baja</Button>
          </div>
        </td>
      </tr>
    ));

    return (
      <tbody>{membersRows}</tbody>
    )
  }

  const getDropMemberConfirmationPopup = (): JSX.Element => {
    return (
      <Modal show={showDropMemberConfirmationModal} onHide={handleDropMemberConfirmationModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmar acción</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Está apunto de dar de baja al socio. Por favor, complete los siguientes campos.</p>
          <Form>
            <Form.Group className="xs-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Razón</Form.Label>
              <Form.Select aria-label="Test" onChange={handleDropMemberReasonChange}>
                <option>Seleccione una opción</option>
                <option value={MemberStatusUpdateReason.Debtor}>Máximo de cuotas sociales impagas excedido</option>
                <option value={MemberStatusUpdateReason.DischargedVoluntarily}>Baja por voluntad propia</option>
              </Form.Select>
            </Form.Group>
            <Form.Group
              className="xs-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Fecha efectiva de baja</Form.Label>
              <Form.Control type="date" value={getDate(new Date(), DEFAULT_SHORT_ISO_DATE_FORMAT)!} onChange={handleDropMemberEffectiveDateChange} />
            </Form.Group>
            <Form.Group
              className="xs-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Comentarios</Form.Label>
              <Form.Control as="textarea" rows={3} onChange={handleDropMemberCommentsChange} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleDropMemberConfirmationModalClose}>
            Cancelar
          </Button>
          <Button variant="primary" disabled={!dropMemberReason || !dropMemberEffectiveDate} onClick={handleDropMember}>
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  // Handlers
  const handleDropMember = async () => {
    if (!dropMemberReason || !dropMemberEffectiveDate) { return; }

    const member: MemberDto = {
      id: selectedMemberId,
      statusId: MemberStatusId.Inactive,
      statusUpdate: {
        statusBeforeId: MemberStatusId.Active,
        statusAfterId: MemberStatusId.Inactive,
        statusUpdateReasonId: dropMemberReason,
        comments: dropMemberComments || null,
        effectiveDate: new Date(dropMemberEffectiveDate)
      }
    }
    await makePatchMembersCallV2(member);
    setShowDropMemberConfirmationModal(false);
    setShowDropMemberToast(true);
  }

  const handleSearchValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const handleSearchCriteriaChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSearchCriteria(e.target.value);
  };

  const handleDropMemberReasonChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setDropMemberReason(e.target.value);
  };

  const handleDropMemberEffectiveDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDropMemberEffectiveDate(e.target.value);
  };

  const handleDropMemberCommentsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDropMemberComments(e.target.value);
  };

  const handleDropMemberConfirmationModalClose = () => setShowDropMemberConfirmationModal(false);

  const handleDropMemberConfirmationModalShow = (e: React.MouseEvent<HTMLButtonElement>) => {
    setSelectedMemberId(e.currentTarget.value);
    setShowDropMemberConfirmationModal(true);
  };

  const handleInactiveMembersCheckboxChange = () => {
    setShowInactiveMembers(!showInactiveMembers);
  };

  // Toast
  const getDropMemberToast = (): JSX.Element => {
    return (
      <ToastContainer className="p-3" position="bottom-end">
        <Toast onClose={() => setShowDropMemberToast(false)} show={showDropMemberToast} delay={3000} autohide>
          <Toast.Header>
            <strong className="xs-auto">Acción completada</strong>
          </Toast.Header>
          <Toast.Body>El socio ha sido dado de baja.</Toast.Body>
        </Toast>
      </ToastContainer>
    )
  }

  const getPagination = () => {
    const number = Number(pageNumber!);
    return (
      <Pagination className="d-flex justify-content-center">
        <Pagination.First onClick={() => handlePaginationItemClick(1)} />
        <Pagination.Prev disabled={number === 1} onClick={() => handlePaginationItemClick(number - 1)} />
        <Pagination.Item active={number === 1} onClick={() => handlePaginationItemClick(1)}>{1}</Pagination.Item>
        {
          pageNumber <= 4
            ? <></>
            : <Pagination.Ellipsis />
        }

        {getPaginationItems()}

        {
          pageNumber >= getMembersResponse.pageCount - 3
            ? <></>
            : <Pagination.Ellipsis />
        }
        <Pagination.Item active={number === getMembersResponse.pageCount} onClick={() => handlePaginationItemClick(getMembersResponse.pageCount)}>{getMembersResponse.pageCount}</Pagination.Item>
        <Pagination.Next disabled={number === getMembersResponse.pageCount} onClick={() => handlePaginationItemClick(number + 1)} />
        <Pagination.Last onClick={() => handlePaginationItemClick(getMembersResponse.pageCount)} />
      </Pagination>
    )
  }

  const getPaginationItems = () => {
    let active = Number(pageNumber!);
    let items = [];
    if (pageNumber <= 3) {
      for (let number = 2; number <= 5; number++) {
        items.push(
          <Pagination.Item key={number} active={number === active} onClick={() => handlePaginationItemClick(number)}>
            {number}
          </Pagination.Item>,
        );
      }
      return items;
    }

    if (pageNumber >= getMembersResponse.pageCount - 2) {
      for (let number = getMembersResponse.pageCount - 4; number < getMembersResponse.pageCount; number++) {
        items.push(
          <Pagination.Item key={number} active={number === active} onClick={() => handlePaginationItemClick(number)}>
            {number}
          </Pagination.Item>,
        );
      }
      return items;
    }

    for (let number = pageNumber - 2; number <= pageNumber + 2; number++) {
      items.push(
        <Pagination.Item key={number} active={number === active} onClick={() => handlePaginationItemClick(number)}>
          {number}
        </Pagination.Item>,
      );
    }
    return items;
  }

  const handlePaginationItemClick = (number: number) => {
    setPageNumber(number);
    navigate(`?page=${number}`);
  }

  return (
    <Row>
      <Row id="member-search">
        <Col>
          <h3>Búsqueda</h3>
          {getMemberSearch()}
        </Col>
        <Col>
          <h3>Filtros</h3>
          {getFilters()}
        </Col>
      </Row>
      <Row id="member-list">
        <Row id="members-list-title-row">
          <Col xs={3}>
            <h3>Listados</h3>
          </Col>
          <Col xs={{ offset: 6, span: 3 }}>
            <div className="d-flex flex-row-reverse">
              <ExportToExcel inputData={membersResponse.results} fileName="Socios"/>
            </div>
          </Col>
        </Row>
        <Row className="pr-0 pl-0 ml-0 mr-0">
          {getTable()}
        </Row>
        <Row className="d-flex justify-content-center">
          {getPagination()}
        </Row>
      </Row>
      {getDropMemberConfirmationPopup()}
      {getDropMemberToast()}
    </Row>
  )
}

export default ListMembers;
