import CurrencyFormat from 'react-currency-format';

interface Props {
  value: number;
}

export default function CurrencyData(props: Props) {
  return (
    <CurrencyFormat value={props.value} displayType={'text'} decimalSeparator={','} thousandSpacing={"3"} thousandSeparator={'.'} decimalScale={2} fixedDecimalScale={true} prefix={'$'} />
  )
}
