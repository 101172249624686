import {useToastConfiguration} from "../../../App";
import {makeGetModulesCall, makePostRoleCall} from "../../../services/authorization-service";
import { Module } from "../../../models/authorization/module";
import React, {useState} from "react";
import {Button, Col, Form, Row, Table} from "react-bootstrap";
import {useLoaderData} from "react-router-dom";
import { TaskDto } from "../../../models/authorization/task-dto";
import FormInput from "../../../components/forms/form-input";
import {BUTTON_CREATE_NEW_LABEL, TOAST_SUCCESSFUL_OPERATION_TITLE} from "../../../constants";
import {useFormik} from "formik";
import {createUserSchema} from "../../../models/validations/create-user-schema";
import * as Yup from "yup";
import { HttpStatusCode } from "axios";
import {ActivityFee} from "../../../models/activity-fee";

export const getModulesLoader = async(): Promise<Module[]> => {
  return (await makeGetModulesCall()).response!;
};

export default function AddRole() {
  const getModulesResponse = useLoaderData() as Module[];
  const { toastConfiguration } = useToastConfiguration();
  const [selectedTasks, setSelectedTasks] = useState<TaskDto[]>([]);

  const formik = useFormik({
    initialValues: {
      name: '',
      description: ''
    },
    validationSchema: Yup.object({}),
    onSubmit: async () => {
      toastConfiguration?.setShow(true);
      const request = {
        name: formik.values.name,
        description: formik.values.description,
        tasks: selectedTasks
      };
      console.log(request);
      const response = await makePostRoleCall(request);
      switch (response.statusCode) {
        case HttpStatusCode.Created: {
          toastConfiguration?.setOperationSuccessful(true);
          toastConfiguration?.setTitle(TOAST_SUCCESSFUL_OPERATION_TITLE);
          toastConfiguration?.setBody('El rol ha sido cargado exitosamente.');
          formik.resetForm();
          break;
        }
      }
    }
  });

  const handleCheckboxChange = (selectedTask: TaskDto) => {
    const index = selectedTasks.findIndex((task) => task.id === selectedTask.id);
    if (index < 0) {
      setSelectedTasks([...selectedTasks, selectedTask]);
    } else {
      setSelectedTasks(selectedTasks.filter((task) => task.id !== selectedTask.id));
    }
  }

  const getTableRows = (): JSX.Element => {
    if (!getModulesResponse) {
      return <></>
    }

    const modulesRows = getModulesResponse.map((module: Module) => (
      module.tasks.map((task: TaskDto) => (
        <tr key={task.id}>
          <td>{module.name}</td>
          <td>{task.name}</td>
          <td>{task.description}</td>
          <td><Form.Check key={task.id} onChange={() => { handleCheckboxChange(task) }} /></td>
        </tr>
      ))
    ));

    return (
      <tbody>{modulesRows}</tbody>
    )
  }

  const getTable = (): React.ReactNode => {
    return (
      <Table responsive striped bordered hover>
        <thead>
        <tr>
          <th>Módulo</th>
          <th>Tarea</th>
          <th>Descripción de la tarea</th>
          <th>¿Tiene permiso?</th>
        </tr>
        </thead>
        {getTableRows()}
      </Table>
    )
  }

  return (

    <Form onSubmit={formik.handleSubmit}>
      <Row>
        <Col md={4}>
          <FormInput
            controlId="name"
            label="Nombre del rol"
            type="text"
            name="name"
            value={formik.values.name}
            onChangeEvent={formik.handleChange}
            onBlurEvent={formik.handleBlur}
            touchedField={formik.touched.name}
            errorField={formik.errors.name}
            placeholder="Administrador"
            isDisabled={false}
          />
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <FormInput
            controlId="description"
            label="Descripción"
            type="textarea"
            name="description"
            value={formik.values.description}
            onChangeEvent={formik.handleChange}
            onBlurEvent={formik.handleBlur}
            touchedField={formik.touched.description}
            errorField={formik.errors.description}
            placeholder="Describa brevemente las características particulares de este rol"
            isDisabled={false}
          />
        </Col>
      </Row>
      <Row>
        <p>Seleccione, teniendo en cuenta los módulos del sistema, qué tareas pueden realizar los usuarios que tengan el rol que está creando actualmente.</p>
        {getTable()}
      </Row>
      <Row>
        <Col md={12}>
          <div className="d-flex justify-content-center">
            <Button variant="primary" type="submit">{BUTTON_CREATE_NEW_LABEL}</Button>
          </div>
        </Col>
      </Row>
    </Form>
  )
}
