import React from 'react'
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import {Button} from "react-bootstrap";
import {useToastConfiguration} from "../App";
import {HttpStatusCode} from "axios";
import {MEMBERSHIP_FEE_DISCRIMINATOR, TOAST_SUCCESSFUL_OPERATION_TITLE} from "../constants";

export interface Props {
  inputData?: unknown[];
  dataSourceFunction?: () => Promise<any>;
  buttonLabel?: string;
  fileName: string;
  addTimestamp?: boolean;
}

export default function ExportToExcel(props: Props) {
  const { toastConfiguration } = useToastConfiguration();
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportData = async () => {
    let data = props.inputData ?? ((props.dataSourceFunction != null) ? (await props.dataSourceFunction())?.data : null);

    if (!data || !data.length) {
      toastConfiguration?.setShow(true);
      toastConfiguration?.setTitle('Sin datos');
      toastConfiguration?.setBody('No hay datos para exportar con los filtros seleccionados.');
      toastConfiguration?.setOperationSuccessful(true);
      return;
    }

    const ws = XLSX.utils.json_to_sheet(data as unknown[]);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const output = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(output, props.fileName + fileExtension);
  };

  return (
    <Button onClick={() => exportData()}><i className="fa-regular fa-file-excel"></i> {props.buttonLabel ?? 'Exportar'}</Button>
  );
}
